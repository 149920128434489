import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend) // use the backend plugin
  .use(initReactI18next)
  .init({
    backend: {
      // path where resources get loaded from
      loadPath: '/lang/{{lng}}.json',
    },
    lng: "ar", // initial language
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
