import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import './header.css';
import LanguageChange from '../../modals/languageChange/languageChange.tsx';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const [showLanguageModal, setShowLanguageModal] = useState(false);

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };

    const homeButton = () => {
        navigate('/');
    }

    return (
        <div>
            <LanguageChange
                show={showLanguageModal}
                onClose={() => setShowLanguageModal(false)}
                onLanguageChange={changeLanguage}
            />
            <div className='header'>
                <div className='page-logo'>
                    <img src='./assets/websitelogo.png' alt="Logo" onClick={() => homeButton()}/>
                </div>
                <div className='header-icons'>
                    <button className='lang-btn' onClick={() => setShowLanguageModal(true)}>{i18n.language}</button>

                </div>
            </div>
        </div>
    )
}

export default Header