import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Payment from './components/paymentPage/Payment.tsx';
import Otp from './components/otpScreen/Otp.tsx';
import './App.css';
import Homepage from './components/homepage/homepage.tsx';
import Msisdn from './components/msisdnVerificationPage/msisdn.tsx';
import Header from './components/header/header.tsx';
import { useTranslation } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import i18n from './lang/i18n.js'; // your i18n configuration file

function App() {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    // i18n.changeLanguage(lang); // Uncomment and use this line to change the language
    console.log('Language changed to:', lang); // For testing
  };

  useEffect(() => {
    document.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  }, [i18n.language]);



  return (
    <I18nextProvider i18n={i18n}>
      <div className='app-container'>
       

        <div className='app-body-container'>
          
          <Router>
          <Header/>
            <Routes>
              <Route path="/payment" element={<Payment />} />
              <Route path="/otp" element={<Otp />} />
              <Route path="/verifymsisdn" element={<Msisdn />} />
              <Route path="/" element={<Homepage />} />
            </Routes>
          </Router>
        </div>
      </div>
    </I18nextProvider>
  );
}

export default App;
