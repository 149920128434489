import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select'; // Import the dropdown component
import './msisdn.css';
import { ITEMS } from '../../constants/items.ts';
import { sendPincodeAPI } from '../../api/api.ts';
import Loader from '../../loader/loader.tsx';
import { useTranslation } from 'react-i18next';

const Msisdn = () => {
  const { t } = useTranslation();

  const [msisdn, setMsisdn] = useState('');
  // const [selectedCountryCode, setSelectedCountryCode] = useState(null); // State for selected country code
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>(''); // Assuming it's a string
  
  const [countryCode, setCountryCode] = useState('+1');
  
  const location = useLocation();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const userId = location?.state?.userId;
  const serviceId = location?.state?.serviceId;
  const selectedItem = location?.state?.selectedItem;
  const selectedCurrency = location?.state?.selectedCurrency;

  const handleVerification = async () => {
    console.log('Verifying MSISDN:', msisdn);
    const serviceId = ITEMS[selectedItem].serviceId;
    const data = {
      msisdn: countryCode + msisdn, // Include country code in the phone number
      serviceId: serviceId,
    };
    setIsLoading(true);
    const apiData = await sendPincodeAPI(data);
    if (apiData && apiData.data.message === 'OK') {
      navigate('/otp', { state: { userId, msisdn: data.msisdn, serviceId } });
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  

  return (
    <>
      <Loader isLoading={isLoading}></Loader>
      <div className="msisdn-container">
        {isError && <p className="error-message">{'Please enter the correct value'}</p>}

       
        

        <h3>
          {ITEMS[selectedItem].name}
          {ITEMS[selectedItem].value} costs
          <br />
          {selectedCurrency} {ITEMS[selectedItem].price[selectedCurrency]}
        </h3>
        <p>{t('The cost will be charged to your mobile phone bill')}</p>
        <p>
          {t('Please enter your')} <b>{t('mobile phone number.')}</b>
          <br />
          {t('You will receive a one-time access code.')}
          <br/>
          {t('Select country code first')}
        </p>

        <select
            value={countryCode}
            onChange={(e) => setCountryCode(e.target.value)}
          >
            {/* Add your country code options here */}
          <option value="1">+1 (USA)</option>
          <option value="964">+964 (Iraq)</option>
          <option value="20">+20 (Egypt)</option>
          <option value="213">+213 (Algeria)</option>
          <option value="966">+966 (Saudi Arabia)</option>
          <option value="973">+973 (Bahrain)</option>
          <option value="965">+965 (Kuwait)</option>
          <option value="974">+974 (Qatar)</option>
          
          <option value="+968">+968 (Oman)</option>
          <option value="+974">+974 (Qatar)</option>
          <option value="+971">+971 (United Arab Emirates)</option>
            {/* Add more options as needed */}
          </select>

        <input
          type="text"
          placeholder={t('Enter your phone number')}
          value={msisdn}
          onChange={(e) => setMsisdn(e.target.value)}
        />
        <button onClick={handleVerification} className={`${!msisdn ? 'disabled-btn' : ''}`} disabled={!msisdn}>
          {t('NEXT')}
        </button>
      </div>
    </>
  );
};

export default Msisdn;
