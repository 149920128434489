export const ITEMS = {
  "LCC 1": {
    serviceId: 650,
    name: "LudoChatGolds",
    price: {
      USD: "1",
      IQD: "1300",
    },
    value: "8000",
  },
  "LCC 2": {
    serviceId: 635,
    name: "LudoChatGolds",
    price: {
      USD: "3",
      IQD: "3900",
    },
    value: "24000",
  },
  "LCC 3": {
    serviceId: 636,
    name: "LudoChatGolds",
    price: {
      USD: "5",
      IQD: "6500",
    },
    value: "42000",
  },
  "LCC 4": {
    serviceId: 637,
    name: "LudoChatGolds",
    price: {
      USD: "10",
      IQD: "13000",
    },
    value: "90000",
  },
  "LCC 5": {
    serviceId: 638,
    name: "LudoChatGolds",
    price: {
      USD: "30",
      IQD: "39000",
    },
    value: "260000",
  },
  "LCG 1": {
    serviceId: 642,
    name: "LudoChatDiamonds",
    price: {
      USD: "1",
      IQD: "1300",
    },
    value: "80",
  },
  "LCG 2": {
    serviceId: 643,
    name: "LudoChatDiamonds",
    price: {
      USD: "3",
      IQD: "3900",
    },
    value: "240",
  },
  "LCG 3": {
    serviceId: 644,
    name: "LudoChatDiamonds",
    price: {
      USD: "5",
      IQD: "6500",
    },
    value: "420",
  },
  "LCG 4": {
    serviceId: 645,
    name: "LudoChatDiamonds",
    price: {
      USD: "10",
      IQD: "13000",
    },
    value: "880",
  },
  "LCG 5": {
    serviceId: 646,
    name: "LudoChatDiamonds",
    price: {
      USD: "30",
      IQD: "39000",
    },
    value: "2600",
  },
};
