import React, { useState, useRef } from 'react';
import './Otp.css';
import { verifyPincodeAPI } from '../../api/api.ts';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../loader/loader.tsx';
import { useTranslation } from 'react-i18next';

const Otp = () => {
  const { t ,i18n} = useTranslation();

  const navigate = useNavigate();

  const [otp, setOtp] = useState(['', '', '', '', '']);
  const inputRefs = useRef([...Array(5)].map(() => React.createRef()));
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState(false);

  const userId = location?.state?.userId;
  const msidn = location?.state?.msisdn;
  const serviceId = location?.state?.serviceId;
  console.log("location>>", userId, msidn)

  const handleInputChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);

    console.log(index)
    // Move to the next input
    if (index < inputRefs.current.length - 1 && value !== '' && !isNaN(value)) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      // Erase the digit and move to the previous input on backspace
      const newOtp = [...otp];
      newOtp[index] = '';

      setOtp(newOtp);

      if (index > 0) {
        inputRefs.current[index - 1].current.focus();
      }
    }
  };
  const handleOtpSubmit = async () => {
    const isFilled = otp.every((value) => value !== '');
    if (isFilled) {
      const otpString = otp.join('');
      // console.log('OTP String:', otpString);
      const data = {
        userId: userId,
        msidn: msidn,
        serviceId: serviceId,
        otp: otpString,
        lang:i18n.language
      }
      setIsLoading(true);
      const apiData = await verifyPincodeAPI(data);
      if (apiData.data.message === "OK") {
        //alert("OTP Verified");
        setPaymentSuccess(true);
      } else {
        //alert("Invalid OTP");
        setPaymentError(true);
      }
      setIsLoading(false);
    }
  };

  const handlePaymentSuccess = () => {
    setPaymentSuccess(true);
  };

  const handleClosePopup = () => {
    setPaymentSuccess(false);
    setPaymentError(false);
    navigate('/', { state: {} });
  };


  return (
    <>
      <Loader isLoading={isLoading}></Loader>

      <div className='main-otp-container'>

        <div className='otp-container'>
          <h4>{t('Please enter the code to verify')}</h4>
          <p>{t('Enter one time access code you received.')}</p>


          <div className='otp-input-container'>
            {otp.map((digit, index) => (
              <input
                key={index}
                ref={inputRefs.current[index]}
                type='text'
                value={digit}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                maxLength={1}
                inputMode="numeric"
              />
            ))}
          </div>
          {/* <p className='error-message'>{'Invalid code'}</p> */}
          {paymentSuccess && (
            <div className="payment-popup">
              <div className="popup-content">
                <p>Payment Successful!</p>
                <button onClick={handleClosePopup}>Close</button>
              </div>
            </div>
          )}
          {paymentError && (
            <div className="payment-popup error">
              <div className="popup-content">
                <p>Payment Error. Please try again.</p>
                <button onClick={handleClosePopup}>Close</button>
              </div>
            </div>
          )}

          <button className={`submit-btn ${otp.join('').length != 5 ? 'disabled-btn' : ''}`} onClick={handleOtpSubmit} disabled={
            otp.join('').length != 5
          }>{t('Submit')}</button>
        </div>
      </div>
    </>
  );
};

export default Otp;
