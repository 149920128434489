import React, { useEffect, useState } from "react";
import "./Payment.css";
import { sendPincodeAPI, verifyUserIdAPI } from "../../api/api.ts";
import { useNavigate } from "react-router-dom";
import { ITEMS } from "../../constants/items.ts";
import Loader from "../../loader/loader.tsx";
import { useTranslation } from "react-i18next";

const Payment = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("method1");
  const [selectedItem, setSelectedItem] = useState("LCC 1");
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedTab, setSelectedTab] = useState("gold");
  const [msisdn, setmsisdn] = useState(0);
  const [userId, setUserId] = useState(0);
  const [isError, setIsError] = useState(false);
  const [userIdVerified, setUserIdVerified] = useState(false);
  const [invalidUserId, setInvalidUserId] = useState(false);
  const [serviceId, setServiceId] = useState();
  const [username, setUsername] = useState("leywin");
  const [isLoading, setIsLoading] = useState(false);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    const currentSelectedTab = items.find((item) => item.id === tab);
    if (currentSelectedTab) setSelectedItem(currentSelectedTab.types[0].id);
  };

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
  };

  const handleItemChange = (item) => {
    setSelectedItem(item);
  };

  const getActiveItems = () => {
    const activeTabItems = items.find((item) => item.id === selectedTab);
    return activeTabItems ? activeTabItems.types : [];
  };

  useEffect(() => {
    i18n.language === 'ar' ? setSelectedCurrency('IQD') : setSelectedCurrency('USD');
  }, [i18n.language]);
  
  useEffect(() => {
    const currentSelectedTab = items.find((item) => item.id === selectedTab);
    if (currentSelectedTab) {
      const currentSelectedItem = currentSelectedTab.types.find(
        (itemtypes) => itemtypes.id === selectedItem
      );
      console.log(selectedItem, currentSelectedItem, currentSelectedTab);
      if (currentSelectedItem)
        setTotalAmount(parseInt(currentSelectedItem.price[selectedCurrency]));
    }
  }, [selectedItem, selectedCurrency, selectedTab]);

  const paymentMethods = [
    {
      id: "method1",
      label: t("Carrier Billing"),
      image: "./assets/zain-payment.png",
    },
  ];

  const items = [
    {
      id: "gold",
      types: [
        {
          id: "LCC 1",
          price: {
            USD: "1",
            IQD: "1300",
          },
          value: "8000",
          image: "./assets/goldcoins1.png",
          name: "YallaPayGolds8000",
        },
        {
          id: "LCC 2",
          price: {
            USD: "3",
            IQD: "3900",
          },
          value: "24000",
          image: "./assets/goldcoins1.png",
          name: "YallaPayGolds24000",
        },
        {
          id: "LCC 3",
          price: {
            USD: "5",
            IQD: "6500",
          },
          value: "42000",
          image: "./assets/goldcoins1.png",
          name: "YallaPayGolds42000",
        },
        {
          id: "LCC 4",
          price: {
            USD: "10",
            IQD: "13000",
          },
          value: "90000",
          image: "./assets/goldcoins1.png",
          name: "YallaPayGolds90000",
        },
        {
          id: "LCC 5",
          price: {
            USD: "30",
            IQD: "39000",
          },
          value: "260000",
          image: "./assets/goldcoins1.png",
          name: "YallaPayGolds260000",
        },
      ],
    },
    {
      id: "diamonds",
      types: [
        {
          id: "LCG 1",
          price: {
            USD: "1",
            IQD: "1300",
          },
          value: "80",
          image: "./assets/diamond1.png",
          name: "YallaPayDiamonds80",
        },
        {
          id: "LCG 2",
          price: {
            USD: "3",
            IQD: "3900",
          },
          value: "240",
          image: "./assets/diamond1.png",
          name: "YallaPayDiamonds240",
        },
        {
          id: "LCG 3",
          price: {
            USD: "5",
            IQD: "6500",
          },
          value: "420",
          image: "./assets/diamond1.png",
          name: "YallaPayDiamonds420",
        },
        {
          id: "LCG 4",
          price: {
            USD: "10",
            IQD: "13000",
          },
          value: "880",
          image: "./assets/diamond1.png",
          name: "YallaPayDiamonds880",
        },
        {
          id: "LCG 5",
          price: {
            USD: "30",
            IQD: "39000",
          },
          value: "2600",
          image: "./assets/diamond1.png",
          name: "YallaPayDiamonds2600",
        },
      ],
    },
  ];

  const paymentMethodsBlock = () => {
    return (
      <div className="payment-block">
        <h3>2. {t("Payment Method")}</h3>
        <div className="payment-methods">
          {paymentMethods.map((method) => (
            <label
              key={method.id}
              className={`payment-method ${
                selectedPaymentMethod === method.id && "selected"
              }`}
            >
              <input
                type="radio"
                name="paymentMethod"
                value={method.id}
                checked={selectedPaymentMethod === method.id}
                onChange={() => handlePaymentMethodChange(method.id)}
              />
              <div>
                <img src={method.image} alt="" className="method-logo" />
              </div>
              <div className="payment-method-label">{method.label}</div>
            </label>
          ))}
        </div>
      </div>
    );
  };

  const chooseItemBlock = () => {
    return (
      <div className="payment-block">
        <h3>3. {t("Choose an item")}</h3>
        <div className="tabs-container">
          <div
            className={`tab ${selectedTab === "gold" && "active"}`}
            onClick={() => handleTabChange("gold")}
          >
            {t("Gold")}
          </div>
          <div
            className={`tab ${selectedTab === "diamonds" && "active"}`}
            onClick={() => handleTabChange("diamonds")}
          >
            {t("Diamonds")}
          </div>
        </div>

        <div className="items">
          {getActiveItems().map((item) => (
            <label
              key={item.id}
              className={`item ${selectedItem === item.id && "selected"}`}
            >
              <input
                type="radio"
                name="item"
                value={item.id}
                checked={selectedItem === item.id}
                onChange={() => handleItemChange(item.id)}
              />
              <div>
                <img src={item.image} alt="" className="coins-logo" />
              </div>
              <div className="item-labels">
                <div className="item-label-amt">
                  {selectedCurrency} {item.price[selectedCurrency]}
                </div>
                <div className="item-label-coins">{item.value}</div>
              </div>
            </label>
          ))}
        </div>
      </div>
    );
  };

  const handlePayNow = async () => {
    navigate("/verifymsisdn", {
      state: { userId, msisdn, serviceId, selectedItem, selectedCurrency },
    });
    // const serviceId = getServiceId(selectedItem);
    // const data = {
    //   msisdn: msisdn,
    //   serviceId: serviceId
    // }
    // const apiData = await sendPincodeAPI(data);
    // if (apiData && apiData.status === "Success") {
    //   navigate('/verifymsisdn', { state: { userId, msisdn, serviceId, selectedItem } });
    // } else {
    //   setIsError(true);
    // }
  };

  const handleUserIdVerfication = async () => {
    setIsLoading(true);
    const responseData = await verifyUserIdAPI({
      userId: userId,
    });
    if (responseData.status) {
      setUserIdVerified(true);
      setUsername(responseData.userName);
    } else {
      setInvalidUserId(true);
    }
    setIsLoading(false);
  };

  const handleMsisdnIdChange = (event) => {
    setmsisdn(parseInt(event.target.value));
  };

  const handleUserIdChange = (event) => {
    setUserId(parseInt(event.target.value));
  };

  const removeUserId = (event) => {
    setUserIdVerified(false);
    setInvalidUserId(false);
  };

  const getServiceId = (key) => {
    return ITEMS[key].serviceId || null;
  };

  return (
    <>
      <Loader isLoading={isLoading}></Loader>

      <div className="main-container">
        {/* <div className='header'>
        <div className='page-logo'>
          PAY
        </div>
      </div> */}
        <div className="payment-container">
          {isError && (
            <div className="error-message-container">
              <p className="error-message">{"Some Error Occured"}</p>
            </div>
          )}
          {/* <div className="payment-block">
          <h3>1. MSISDN ID</h3>
          <div className="input-container">
            <img src='./assets/ludo-logo.jpg' alt="Logo" className="input-logo" />
            <input
              type="text"
              placeholder="Please enter msisdn ID"
              className="input-userid"
              onChange={handleMsisdnIdChange}
            />
          </div>

        </div> */}

          <div className="payment-block">
            <h3>1. {t("User Game ID")}</h3>
            {userIdVerified ? (
              <div className="userid-verified">
                <img
                  src="./assets/ludo-logo.jpg"
                  alt="Logo"
                  className="input-logo"
                />
                <div className="userid-verified-details">
                  <p className="username">{username}</p>
                  <p className="userid">id: {userId}</p>
                </div>
                <button className="cross-btn" onClick={removeUserId}>
                  X
                </button>
              </div>
            ) : (
              <>
                <div className="input-container">
                  <img
                    src="./assets/ludo-logo.jpg"
                    alt="Logo"
                    className="input-logo"
                  />
                  <input
                    type="text"
                    placeholder={t("Please enter User ID")}
                    className="input-userid"
                    onChange={handleUserIdChange}
                  />
                </div>
                {invalidUserId && (
                  <p className="error-message">Invalid UserId</p>
                )}
                <button
                  className={`userid-btn ${!userId ? "disabled-btn" : ""}`}
                  onClick={handleUserIdVerfication}
                  disabled={!userId}
                >
                  {t("OK")}
                </button>
              </>
            )}
          </div>

          {paymentMethodsBlock()}
          {chooseItemBlock()}
        </div>
        <div className="footer">
          <div className="page-logo">
            {t("Total")}:
            <span className="totalAmount">
              {" "}
              {selectedCurrency} {totalAmount}
            </span>
          </div>
          <button
            className={`paynow-btn ${!userIdVerified ? "disabled-btn" : ""}`}
            onClick={handlePayNow}
            disabled={!userIdVerified}
          >
            {t("Pay Now")}
          </button>
        </div>
      </div>
    </>
  );
};

export default Payment;
