// Homepage.jsx

import React from 'react';
import './homepage.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Homepage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const cardData = [
        {
            title: 'TOWN STARS',
            image: './assets/townlogo.jpg',
            description: t('Anytime, anywhere build on your phone!!'),
            buttonText: t('Recharge'),
        },
        {
            title: t('LUDO CHAT'),
            image: './assets/ludo-logo.jpg',
            description: t('Ludo chat - Popular Ludo Game with Voice Chat!!'),
            buttonText: t('Recharge'),
        },
        // Add more card data as needed
    ];

    const handleRecharge = () => {
        navigate('/payment');
    }

    return (
        <div className='homepage-main-container'>
            <div className='banner'>
                <img src={'./assets/apps/yalla.png'} className="banner-image" style={{ objectFit: 'cover', height: '100%', width: '100%' }} />
            </div>
            <h1>{t('Apps')}</h1>
            <div className="card-list-container">
                <div className="card-list">
                    {cardData.map((card, index) => (
                        <div key={index} className="card">
                            <div className="card-content">
                                <img src={card.image} alt={card.title} className="card-image" />
                                <div>
                                    <h3>{card.title}</h3>
                                    <p className='card-description'>{card.description}</p>
                                </div>

                            </div>
                            <div>
                                <button className={`recharge-btn ${!index ? 'disabled-btn' : ''}`} disabled={!index} onClick={handleRecharge}>{card.buttonText}</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default Homepage;
