import React, { useState } from 'react';
import './languageChange.css'; // Import the CSS for styling
import { useTranslation } from 'react-i18next';

const LanguageChange = ({ show, onClose, onLanguageChange }) => {
  const { t } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleOk = () => {
    onLanguageChange(selectedLanguage);
    onClose();
  };

  if (!show) {
    return null;
  }

  return (
    <div className="language-modal">
      <div className="language-modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h3>{t('Select Language')}</h3>
        <div className="language-options">
          <div
            className={`language-option ${selectedLanguage === 'en' && 'selected'}`}
            onClick={() => setSelectedLanguage('en')}
          >
            <input
              type="radio"
              value="en"
              checked={selectedLanguage === 'en'}
              onChange={handleLanguageChange}
            />
            <span>{t('English')}</span>
          </div>
          <div
            className={`language-option ${selectedLanguage === 'ar' && 'selected'}`}
            onClick={() => setSelectedLanguage('ar')}
          >
            <input
              type="radio"
              value="ar"
              checked={selectedLanguage === 'ar'}
              onChange={handleLanguageChange}
            />
            <span>{t('Arabic')}</span>
          </div>
        </div>
        <button type="button" onClick={handleOk}>{t('OK')}</button>
      </div>
    </div>
  );
};

export default LanguageChange;
