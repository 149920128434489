// export const sendPincodeAPI = async (data: any) => {
//   const apiUrl =
//     "https://services.mediaworldiq.com:456/dcb/API/VMS-OneTimePurchase/actions/sendPincode";
//   const shortcode = 4049;
//   const spId = 199;
//   const msisdn = data.msisdn;
//   const serviceId = data.serviceId;

//import { useTranslation } from "react-i18next";

//   const url = `${apiUrl}?msisdn=${msisdn}&shortcode=${shortcode}&serviceId=${serviceId}&spId=${spId}`;
//   console.log("url hitting sendpincodeapi---", url);
//   try {
//     const response = await fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });

//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }

//     const data = await response.json();
//     console.log("API Response:", data);
//     return data;
//   } catch (error) {
//     console.error("Error:", error);
//   }
// };

// export const verifyPincodeAPI = async (data) => {
//   const apiUrl =
//     "https://services.mediaworldiq.com:456/dcb/API/VMS-OneTimePurchase/actions/verifyPincode";
//   const shortcode = 4049;
//   const spId = 199;
//   const serviceId = data.serviceId;
//   const msisdn = data.msidn;
//   const pincode = data.otp;
//   const userId = data.userId;

//   const url = `${apiUrl}?msisdn=${msisdn}&shortcode=${shortcode}&serviceId=${serviceId}&spId=${spId}&pincode=${pincode}`;
//   console.log("url hitting verifyotp---", url, userId);

//   try {
//     const response = await fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });

//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }

//     const data = await response.json();
//     console.log("API Response:", data);
//     return data;
//   } catch (error) {
//     console.error("Error:", error);
//   }
// };

export const sendPincodeAPI = async (data:any) => {
  const apiUrl = 'https://services.mediaworldiq.com:456/dcb/API/VMS-OneTimePurchase/actions/sendPincode';
  const user = 'shebajoy';
  const password = 'sheb@j0y23!';
 
  const shortcode = 4049;
  const spId = 199;
  const msisdn = data.msisdn;
  const serviceId = data.serviceId;

  //const url = `${apiUrl}?msisdn=${msisdn}&shortcode=${shortcode}&serviceId=${serviceId}&spId=${spId}`;
  const url = `${apiUrl}?user=${user}&password=${password}&msisdn=${msisdn}&shortcode=${shortcode}&serviceId=${serviceId}&spId=${spId}`;
  console.log("url hitting sendpincodeapi---");
  try {
    // const response = await fetch(url, {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // });

    const requestData = {
      url:url
    };

    const response = await fetch("https://request.ludogcc.com/loopback/api/purchasevalues/sendPinCode", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    });
      //console.log(response,"apiresponse>>>>>>>>>>>>>>>>>>>>>","https://services.mediaworldiq.com:456/testip.php")


    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    console.log('API Response:', data);
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
};

export const verifyPincodeAPI = async (data) => {
const apiUrl = 'https://services.mediaworldiq.com:456/dcb/API/VMS-OneTimePurchase/actions/verifyPincode';
const user = 'shebajoy';
const password = 'sheb@j0y23!';
const shortcode = 4049;
const spId = 199;
const serviceId = data.serviceId;
const msisdn = data.msidn;
let pincode = data.otp;
const userId = data.userId
//const { i18n } = useTranslation();
console.log("currentlang>>>>>>>>>>>>>>>>>>>>>",data.lang)
if(data.lang === 'ar'){
pincode=reverseOTP(pincode)
}

//const url = `${apiUrl}?msisdn=${msisdn}&shortcode=${shortcode}&serviceId=${serviceId}&spId=${spId}&pincode=${pincode}`;
const url = `${apiUrl}?user=${user}&password=${password}&msisdn=${msisdn}&shortcode=${shortcode}&serviceId=${serviceId}&spId=${spId}&pincode=${pincode}`;


console.log("url hitting verifyotp---");

try {
  const requestData = {
    url:url,
    serviceId :serviceId,
    userId:userId
  };
  const response = await fetch("https://request.ludogcc.com/loopback/api/purchasevalues/VerifyPinCode", {
    method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  console.log('API Response:', data);
  return data;
} catch (error) {
  console.error('Error:', error);
}
};

function reverseOTP(otp) {
  // Convert the OTP to a string
  let otpString = otp.toString();

  // Reverse the string
  let reversedString = otpString.split('').reverse().join('');

  // Convert the reversed string back to a number
  let reversedOTP = parseInt(reversedString);

  return reversedOTP;
}



export const verifyUserIdAPI = async (data) => {
  try {
    const requestData = {
      userId: data.userId,
    };
    console.log(requestData)
    const response = await fetch(
      "https://request.ludogcc.com/loopback/api/purchasevalues/VerifyUser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log("API Response:", responseData.data);
    return responseData.data;
  } catch (error) {
    console.error("Error:", error);
  }
};
